/* Media Queries for different screen sizes */
@media (max-width: 1200px) { /* 1200px equivalent in rem */
    .sidebar {
        width: 95%;
    }
    
    .search-container, #crypto-management-section, #transaction-section {
        margin-bottom: 0.5rem;
    }
    .toggle-accordion {
        font-size: 1rem;
    }
    .accordion-title {
        margin-right: 0.3rem;
    }
 

    .crypto-logo {
        width: 2rem;
    }
    

    

   

    #crypto-table-resume {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.8rem;
        width: 95%;
        margin-bottom: 0.5rem;
        border-collapse: collapse;
        -webkit-overflow-scrolling: touch; /* pour un défilement fluide sur iOS */
    }

    #crypto-table {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.8rem;
        width: 95%;
        margin-bottom: 0.5rem;
        border-collapse: collapse;
        -webkit-overflow-scrolling: touch; /* pour un défilement fluide sur iOS */
    }

    #crypto-table th {
        margin-left: 1rem; 
        margin-right: 1rem;
      
    }

    #crypto-table td {
        padding-left: 2rem; 
        padding-right: 2rem;
        
    }

    #crypto-table button{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        align-content: center;
        width: 2.5rem; /* Réduit encore plus la taille du logo pour les écrans mobiles */
        font-size: 0.6rem;
    }

    #crypto-table img, #crypto-table-resume img {
        align-content: center;
        width: 0.6rem; /* Réduit encore plus la taille du logo pour les écrans mobiles */
    }

    

    #crypto-table td, #crypto-table-resume td, #crypto-table th, #crypto-table-resume th , #crypto-table tr, #crypto-table-resume tr {
        padding: 0;
    }

    .table-scroll-container{
        width: 100%;
    }
    .table-scroll-container_resume{
        width: 100%;
        font-size: 1rem;
    }

    #total-portfolio-chart-container {
        font-size: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    :root {
        --tooltip-label-font-size: 15px; /* valeur par défaut */
    }

    .footer {
        font-size: 0.7rem;
       
    }

    .header {
        margin-top: 1.6rem;
        font-size: 0.7rem;
    }

    
    #crypto-section-general{
        width: 85%;
    }
    #crypto-management-section{
        width: 85%;
    }

    #crypto-name-add{
        width: 100%;
    }
    #crypto-id-add{
        width: 100%;
    }
    

    .modal-content-detail {
        
        max-width: 100%;
        width: 95%;
    }

    .crypto-details{
        font-size: 0.6rem;
        width: 95%;
    }

    .crypto-header img{
        width: 1.5rem;
    }

    .table-scroll {
        overflow:scroll;
        -webkit-overflow-scrolling: touch;
        font-size: 0.6rem;
        
    }

    .table-scroll th {
        margin: 0; 
        padding: 0;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
      
    }

    .table-scroll td {
        margin: 0; 
        margin-bottom: 0.1rem;
        padding: 0;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
      
    }

    .table-scroll button {
        margin-top: 0;
        padding: 0;
        
    }

    .table-scroll-resume{
        font-size: 0.6rem;
    }

    .container-graphique {
        width: 90%;
    }

    .close-modal button {
       font-size: 0.6rem;
       width: 2.7rem;
       padding: 0;
    }


}