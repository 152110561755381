@media (max-width: 429px) { /* 429px equivalent in rem */
    .sidebar {
        width: 95%;
    }

    .search-container, #crypto-management-section, #transaction-section {
        margin-bottom: 0.5rem;
    }

    .search-result-item {
        display: flex;
        overflow:visible;
        z-index: 1000;
        width: 50rem;
        align-items: center;
        padding: 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        border-bottom: 1px solid #eee;
        left: 0;
    }
    
    .search-result-item:hover {
        background-color: #7a8070;
    }
    
    .crypto-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 50%;
    }
    
    .crypto-name {
        font-weight: bold;
        margin-right: 8px;
        font-size: 0.7em;
    }
    
    .crypto-id {
        margin-right: 10px;
        font-size: 0.7em;
        color: #888;
    }
    
    .crypto-symbol {
        margin-right: 10px;
        font-weight: bold;
        color: #555;
        font-size: 0.7em;
    }
    
    .crypto-price {
        color: green;
        font-size: 0.7em;
    }

    
    .toggle-accordion {
        font-size: 1rem;
    }
    .accordion-title {
        margin-right: 0.3rem;
    }

    .crypto-logo {
        width: 2rem;
    }

    #crypto-table-resume {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.8rem;
        width: 95%;
        margin-bottom: 0.5rem;
        border-collapse: collapse;
        -webkit-overflow-scrolling: touch; /* pour un défilement fluide sur iOS */
    }

    #crypto-table {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.7rem;
        width: 80%;
        margin-bottom: 0.5rem;
        border-collapse: collapse;
        -webkit-overflow-scrolling: touch; /* pour un défilement fluide sur iOS */
    }

    #crypto-table th {
        margin-left: 1rem; 
        margin-right: 1rem;
    }

    #crypto-table td {
        padding-left: 1rem; 
        padding-right: 1rem;
        
    }

    #crypto-table button {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        align-content: center;
        width: 2.5rem; /* Réduit encore plus la taille du logo pour les écrans mobiles */
        font-size: 0.6rem;
    }

    #crypto-table img, #crypto-table-resume img {
        align-content: center;
        width: 0.6rem; /* Réduit encore plus la taille du logo pour les écrans mobiles */
    }

    #crypto-table td, #crypto-table-resume td, #crypto-table th, #crypto-table-resume th , #crypto-table tr, #crypto-table-resume tr {
        padding: 0;
    }

    .table-scroll-container {
        width: 100%;
    }
    .table-scroll-container_resume {
        width: 100%;
        font-size: 1rem;
    }

    #total-portfolio-chart-container {
        font-size: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    :root {
        --tooltip-label-font-size: 30px; /* valeur par défaut */
    }

    .footer {
        font-size: 0.7rem;
    }

    .header {
        margin-top: 1.6rem;
        font-size: 0.7rem;
    }

    #crypto-section-general {
        width: 100%;
    }
    #crypto-management-section {
        width: 85%;
    }

    #crypto-name-add {
        width: 100%;
    }
    #crypto-id-add {
        width: 100%;
    }

    .modal-content-detail {
        max-width: 100%;
        width: 95%;
    }

    .crypto-details {
        font-size: 0.6rem;
        width: 95%;
    }

    .crypto-header img {
        width: 1.5rem;
    }

    .table-scroll {
        overflow:scroll;
        -webkit-overflow-scrolling: touch;
        font-size: 0.7rem;
    }

    .table-scroll th {
        margin: 0; 
        padding: 0;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
    }

    .table-scroll td {
        margin: 0; 
        margin-bottom: 0.1rem;
        padding: 0;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
    }

    .table-scroll button {
        margin-top: 0;
        padding: 0;
    }

    .table-scroll-resume {
        font-size: 0.8rem;
    }

    .container-graphique {
        width: 85%;
    }

    .close-modal button {
       font-size: 0.6rem;
       width: 2.7rem;
       padding: 0;
    }
}
