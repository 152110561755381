/* Reset some default browser styles */

:root {
    --tooltip-label-font-size: 12px; /* valeur par défaut */
}

body, h1, h2, h3, p {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
}

body {
    background-color: #232323; /* Dark theme background */
    color: #f0f0f0; /* Light text for dark theme */
}

.container {
    max-width: 75%; /* Relative width to the viewport */
    margin: 0 auto; /* Centering the content */
    padding: 1rem; /* Using rem for spacing */
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

input, button, label, select, textarea, a {
    margin-top: 0.825rem; /* Using rem for spacing */
}

.lien_objet_auth {
    margin-top: 0.825rem;
    cursor: pointer;
    text-decoration: underline;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
}

.modal-content {
    max-width: 31.25rem; 
    width: 100%; 
    padding: 1.25rem; 
    border-radius: 0.5rem; 
    background-color: beige;
    color: #1a1a1a;
    box-shadow: 0px 0px 15px rgba(255, 254, 254, 0.2);
    overflow: scroll;
}

/* Notification Styles */
.notification.success {
    color: #008000;
}

.notification.error {
    color: red;
}

.notification.warning {
    color: orange;
}

.notification.normal {
    color: #EAEAEA;
}

.header {
    background-color: #292929;
    padding: 1rem 0;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    width: 95%;
}

.footer {
    font-size: 1rem;
    background-color: #1a1a1a;
    padding: 1rem 0;
    text-align: center;
    position: relative;
/*    top:30%;*/
    width: 95%;
}

.footer ul {
    list-style-type: none;
    padding: 0;
}

.footer li {
    margin: 0.5rem 0;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px; 
    background-color: #2b2b2b; 
    overflow-x: hidden;
    transition: all 0.3s;
    padding: 1rem;
    z-index: 1000; 
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); 
}

.menu-icon {
    position: absolute;
    top: 1rem;
    left: 1rem;
    cursor: pointer;
    color: #f0f0f0;
    z-index: 1001; 
}

.sidebar-close-container {
    position: relative;
}

.close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    color: #f0f0f0;
}

#user-info {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
}

#user-info button, #user-info a {
    width: 95%; 
    text-align: left;
    margin: 0.5rem 0;
}

.accordion-title {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

.toggle-accordion {
    font-size: 1.5rem;
    cursor: pointer;
}

.centered-content-homecomponent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center;
    height: 100vh;
}

.documentation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
}

.container_encodage {
    border: 0.0625rem solid #555;
    border-radius: 0.3125rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #333;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.accordion-controls {
    display: flex;
    align-items: center;
}

.label_centre {
    display: inline-block;
    min-width: 9.375rem;
    white-space: nowrap;
}

.search-icon {
    margin-left: 0.3125rem;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    top: 0.4375rem;
}

#crypto-section-general {
    display: flex;
    flex-wrap: wrap;
}

#crypto-management-section, #transaction-section {
    flex: 1;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.titre_encodage_section {
    text-align: center;
    text-decoration: underline;
}

.search-container {
    display: flex;
    flex-direction: row;
}

#crypto-table {
    border: 0.0625rem solid #555;
    border-radius: 0.3125rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #333;
}

#crypto-table-resume {
    border: 0.0625rem solid #555;
    border-radius: 0.3125rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #333;
}

#total-portfolio-chart-container {
    border: 0.0625rem solid #555;
    border-radius: 0.3125rem;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: #333;
}

.underTitre {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-decoration: underline;
}

#crypto-table, #crypto-table-resume {
    width: 95%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

#crypto-table th, #crypto-table td, #crypto-table-resume th, #crypto-table-resume td {
    border: 1px solid #f2f2f2;
    padding: 8px;
    text-align: center; /* Centrer le contenu horizontalement */
    vertical-align: middle; /* Centrer le contenu verticalement */
}

.modal-content-detail {
    background-color: #292929;
    border-radius: 0.5rem;
    overflow: hidden;
    max-width: 95%;
    width: 90%;
    overflow-y: auto;
    max-height: 80vh;
    padding: 0.1rem;
}

.crypto-details {
    width: 100%;
    padding: 1rem;
    overflow-x: auto;
    margin: 0 auto;
    width: 90%;
    font-size: 1rem;
}

.search-wrapper {
    display: flex;
    align-items: center;
    line-height: 1;
    position: relative;
    width: 100%;
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: #1a1a1a;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow-x: scroll;
}

/* Pour tous les écrans */
.table-scroll {
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    text-align: center; /* Centrer le contenu horizontalement */
    vertical-align: middle; /* Centrer le contenu verticalement */
    border: 0.0625rem solid #555;
    border-radius: 0.3125rem;
}

.table-scroll th, .table-scroll td {
    border: 1px solid #f2f2f2;
    padding: 8px;
    text-align: center; /* Centrer le contenu horizontalement */
    vertical-align: middle; /* Centrer le contenu verticalement */
}
.crypto-logo {
    width: 3rem; /* Réduction de la taille du logo */
    margin-right: 0.5rem; /* Espacement à droite du logo */
    border-radius: 50%; /* Rend le logo rond si ce n'est pas déjà le cas */
}


.search-result-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.search-result-item:hover {
    background-color: #7a8070;
}

.crypto-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.crypto-name {
    font-weight: bold;
    margin-right: 10px;
}

.crypto-id {
    margin-right: 10px;
    font-size: 0.9em;
    color: #888;
}

.crypto-symbol {
    margin-right: 10px;
    font-weight: bold;
    color: #555;
}

.crypto-price {
    color: green;
}




.crypto-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.crypto-title {
    
    display: flex; /* Alignement horizontal du titre et de l'icône */
    align-items: center; /* Alignement vertical des éléments */
}

.close-modal {
    position: absolute;
    top: -3.5rem; /* Ajustez cette valeur selon vos besoins */
    right: 0;
    margin: 0;
    padding: 0.5rem; /* Ajustez le padding si nécessaire */

}

.crypto-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.crypto-ath-close {
    position: relative; 
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-modal button {
    background-color: red;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.transaction-achat {
    color: #3dd23d; /* vert clair */
}

.transaction-vente {
    color: #df4040; /* rouge clair */
}

.delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: red;
}

.positive {
    color: #3dd23d; /* vert clair */
}

.negative {
    color: #df4040; /* rouge clair */
}

.future-graph-placeholder {
    padding: 1rem;
}

.container-graphique {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#crypto-table{
    width: 100%;
}




@media (max-width: 30rem) { /* 480px equivalent in rem */
    .sidebar {
        width: 95%;
    }
    
    .search-container, #crypto-management-section, #transaction-section {
        margin-bottom: 0.5rem;
    }
    .toggle-accordion {
        font-size: 1rem;
    }
    .accordion-title {
        margin-right: 0.3rem;
    }
 

    .crypto-logo {
        width: 2rem;
    }
    

    

   

    #crypto-table-resume {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.6rem;
        width: 95%;
        margin-bottom: 0.5rem;
        border-collapse: collapse;
        -webkit-overflow-scrolling: touch; /* pour un défilement fluide sur iOS */
    }

    #crypto-table {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.4rem;
        width: 80%;
        margin-bottom: 0.5rem;
        border-collapse: collapse;
        -webkit-overflow-scrolling: touch; /* pour un défilement fluide sur iOS */
    }

    #crypto-table th {
        margin-left: 1rem; 
        margin-right: 1rem;
      
    }

    #crypto-table td {
        padding-left: 2rem; 
        padding-right: 2rem;
        
    }

    #crypto-table button{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        align-content: center;
        width: 2.5rem; /* Réduit encore plus la taille du logo pour les écrans mobiles */
        font-size: 0.6rem;
    }

    #crypto-table img, #crypto-table-resume img {
        align-content: center;
        width: 0.6rem; /* Réduit encore plus la taille du logo pour les écrans mobiles */
    }

    

    #crypto-table td, #crypto-table-resume td, #crypto-table th, #crypto-table-resume th , #crypto-table tr, #crypto-table-resume tr {
        padding: 0;
    }

    .table-scroll-container{
        width: 100%;
    }
    .table-scroll-container_resume{
        width: 100%;
        font-size: 1rem;
    }

    #total-portfolio-chart-container {
        font-size: 1rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    :root {
        --tooltip-label-font-size: 30px; /* valeur par défaut */
    }

    .footer {
        font-size: 0.7rem;
       
    }

    .header {
        margin-top: 1.6rem;
        font-size: 0.7rem;
    }

    
    #crypto-section-general{
        width: 85%;
    }
    #crypto-management-section{
        width: 85%;
    }

    #crypto-name-add{
        width: 100%;
    }
    #crypto-id-add{
        width: 100%;
    }
    

    .modal-content-detail {
        
        max-width: 100%;
        width: 95%;
    }

    .crypto-details{
        font-size: 0.6rem;
        width: 95%;
    }

    .crypto-header img{
        width: 1.5rem;
    }

    .table-scroll {
        overflow:scroll;
        -webkit-overflow-scrolling: touch;
        font-size: 0.6rem;
        
    }

    .table-scroll th {
        margin: 0; 
        padding: 0;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
      
    }

    .table-scroll td {
        margin: 0; 
        margin-bottom: 0.1rem;
        padding: 0;
        padding-left: 0.1rem;
        padding-right: 0.1rem;
      
    }

    .table-scroll button {
        margin-top: 0;
        padding: 0;
        
    }

    .table-scroll-resume{
        font-size: 0.6rem;
    }

    .container-graphique {
        width: 85%;
    }

    .close-modal button {
       font-size: 0.6rem;
       width: 2.7rem;
       padding: 0;
    }
  
}
