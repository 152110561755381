@media (max-width: 1080px) {
    .sidebar {
        width: 500px; /* Légèrement réduit par rapport à 95% */
    }
    
    .search-container, #crypto-management-section, #transaction-section {
        margin-bottom: 0.5rem;
    }
    .toggle-accordion {
        font-size: 0.95rem;
    }
    .accordion-title {
        margin-right: 0.28rem;
    }
 
    .crypto-logo {
        width: 1.9rem;
    }

    #crypto-table-resume, #crypto-table {
        margin-left: auto;
        margin-right: auto;
        font-size: 0.76rem;
        width: 90%;
        margin-bottom: 0.48rem;
        border-collapse: collapse;
        -webkit-overflow-scrolling: touch;
    }

    #crypto-table th, #crypto-table td {
        margin-left: 0.95rem; 
        margin-right: 0.95rem;
    }

    #crypto-table button {
        margin-top: 0.48rem;
        margin-bottom: 0.48rem;
        width: 2.4rem;
        font-size: 0.58rem;
    }

    #crypto-table img, #crypto-table-resume img {
        width: 0.58rem;
    }

    #crypto-table td, #crypto-table-resume td, #crypto-table th, #crypto-table-resume th , #crypto-table tr, #crypto-table-resume tr {
        padding: 0;
    }

    .table-scroll-container, .table-scroll-container_resume {
        width: 95%;
    }

    .table-scroll-container_resume {
        font-size: 0.95rem;
    }

    #total-portfolio-chart-container {
        font-size: 0.95rem;
        margin-left: 0.48rem;
        margin-right: 0.48rem;
    }

    :root {
        --tooltip-label-font-size: 14px;
    }

    .footer, .header {
        font-size: 0.67rem;
    }

    .header {
        margin-top: 1.52rem;
    }

    #crypto-section-general, #crypto-management-section {
        width: 80%;
    }

    #crypto-name-add, #crypto-id-add {
        width: 95%;
    }

    .modal-content-detail {
        max-width: 95%;
        width: 90%;
    }

    .crypto-details {
        font-size: 0.57rem;
        width: 90%;
    }

    .crypto-header img {
        width: 1.43rem;
    }

    .table-scroll {
        font-size: 0.57rem;
    }

    .table-scroll th, .table-scroll td {
        margin: 0; 
        padding-left: 0.095rem;
        padding-right: 0.095rem;
    }

    .table-scroll-resume {
        font-size: 0.57rem;
    }

    .container-graphique {
        width: 85%;
    }

    .close-modal button {
       font-size: 0.57rem;
       width: 2.58rem;
    }
}
